import { E_SCENE, type TScene, TTerminal } from '../types'
export type TPlatform = 'PWA_C' | 'PWA_P' | 'PC_C' | 'PC_P' | 'H5_C' | 'H5_P'

// 根据场景配置多语言, _C 代表下单页 _P 代表个人中心
const h = (PWA_C, PC_C, H5_C, PWA_P, PC_P, H5_P) => ({
  [PWA_C]: {
    PWA_C,
    PC_C,
    H5_C,
    PWA_P,
    PC_P,
    H5_P,
  },
})

const LANG = {
  // GIFT CARD
  ...h('SHEIN_KEY_PWA_24759', 'SHEIN_KEY_PC_26031', '', 'SHEIN_KEY_PWA_35135', 'SHEIN_KEY_PC_33280', 'SHEIN_KEY_H5_41769'),
  // Available
  ...h('SHEIN_KEY_PWA_22586', 'SHEIN_KEY_PC_23748', '', 'SHEIN_KEY_PWA_22586', 'SHEIN_KEY_PC_23748', 'SHEIN_KEY_H5_41747'),
  // Not Available
  ...h('SHEIN_KEY_PWA_35099', 'SHEIN_KEY_PC_33245', '', 'SHEIN_KEY_PWA_35099', 'SHEIN_KEY_PC_33245', 'SHEIN_KEY_H5_41695'),
  // use desc
  ...h('SHEIN_KEY_PWA_35098', 'SHEIN_KEY_PC_23748', '', '', '', ''),
  // ID
  ...h('SHEIN_KEY_PWA_35101', 'SHEIN_KEY_PC_33247', '', 'SHEIN_KEY_PWA_35101', 'SHEIN_KEY_PC_33247', 'SHEIN_KEY_H5_41697'),
  // value
  ...h('SHEIN_KEY_PWA_35102', 'SHEIN_KEY_PC_33248', '', 'SHEIN_KEY_PWA_35102', 'SHEIN_KEY_PC_33248', 'SHEIN_KEY_H5_41698'),
  // Spending Details
  ...h('SHEIN_KEY_PWA_35136', 'SHEIN_KEY_PC_33281', '', 'SHEIN_KEY_PWA_35136', 'SHEIN_KEY_PC_33281', 'SHEIN_KEY_H5_41770'),
  // EXP {0}
  ...h('SHEIN_KEY_PWA_35104', 'SHEIN_KEY_PC_33250', '', 'SHEIN_KEY_PWA_35139', 'SHEIN_KEY_PC_33284', 'SHEIN_KEY_H5_41773'),
  // EFF {0}
  ...h('SHEIN_KEY_PWA_35137', 'SHEIN_KEY_PC_33282', '', 'SHEIN_KEY_PWA_35137', 'SHEIN_KEY_PC_33282', 'SHEIN_KEY_H5_41771'),
  // No Expiry
  ...h('SHEIN_KEY_PWA_35105', 'SHEIN_KEY_PC_33251', '', 'SHEIN_KEY_PWA_35105', 'SHEIN_KEY_PC_33251', 'SHEIN_KEY_H5_41701'),
  // Balance {0}
  ...h('SHEIN_KEY_PWA_35106', 'SHEIN_KEY_PC_33252', '', 'SHEIN_KEY_PWA_35106', 'SHEIN_KEY_PC_33252', 'SHEIN_KEY_H5_41702'),
  // Apply New Gift Card
  ...h('SHEIN_KEY_PWA_35100', 'SHEIN_KEY_PC_33246', '', 'SHEIN_KEY_PWA_35100', 'SHEIN_KEY_PC_33246', 'SHEIN_KEY_H5_41696'),
  // 绑定按钮 Confirm
  ...h('SHEIN_KEY_PWA_28846', 'SHEIN_KEY_PC_28934', '', '', '', ''),
  // No available cards found.
  ...h('SHEIN_KEY_PWA_35143', 'SHEIN_KEY_PC_33288', '', 'SHEIN_KEY_PWA_35143', 'SHEIN_KEY_PC_33288', 'SHEIN_KEY_H5_41777'),
  // Your unavailable list is empty.
  ...h('SHEIN_KEY_PWA_35144', 'SHEIN_KEY_PC_33289', '', 'SHEIN_KEY_PWA_35144', 'SHEIN_KEY_PC_33289', 'SHEIN_KEY_H5_41778'),
  // copy success
  ...h('SHEIN_KEY_PWA_16466', 'SHEIN_KEY_PC_16645', '', 'SHEIN_KEY_PWA_16466', 'SHEIN_KEY_PC_16645', 'SHEIN_KEY_H5_14142'),
}

// 在处理多终端多语言时, 根据用户传入的 scene 和 terminal 返回相应的 TPlatform
const getPlatformType = (scene: TScene, terminal: TTerminal): TPlatform => `${terminal?.toUpperCase()}_${scene === E_SCENE.Checkout? 'C': 'P'}` as TPlatform

/**
 * 获取语言映射
 */
export const getMultLangMap = ({
  platform,
  language = {},
  scene,
  template,
}: {
  platform?: TPlatform, // 兼容旧逻辑 vue2 下线后可删除
  scene: TScene,
  template: TTerminal,
  language?: Record<string, string>
}) => {
  const _platform = platform || getPlatformType(scene, template)
  if (!_platform) return
  return Object.entries(LANG).reduce((acc, [key, value]) => {
    const lang = language?.[value?.[_platform]] || value?.[_platform]
    if (lang) {
      acc[key] = lang
    }
    return acc
  }, {})
}
