export enum E_SCENE {
  PersonalCenter = 'personalCenter',
  Checkout = 'checkout'
}


export enum E_TERMINAL {
  H5 = 'h5',
  PWA = 'pwa',
  PC = 'pc'
}

export type TScene = `${E_SCENE}`
export type TTerminal = `${E_TERMINAL}`
